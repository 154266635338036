(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-tracking-objects"), require("microkernel"));
	else if(typeof define === 'function' && define.amd)
		define("modelfinder-tracking", ["core-tracking-objects", "microkernel"], factory);
	else if(typeof exports === 'object')
		exports["modelfinder-tracking"] = factory(require("core-tracking-objects"), require("microkernel"));
	else
		root["modelfinder-tracking"] = factory(root["core-tracking-objects"], root["microkernel"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__254__, __WEBPACK_EXTERNAL_MODULE__729__) => {
return 