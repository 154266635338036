import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectFilterOpenClose {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectFilterOpenClose._instance) {
			return ClickEventObjectFilterOpenClose._instance;
		}

		this.filter = document.querySelector('.modelfinder-filter-j-filter');
		this.toggleButton = document.querySelector('.modelfinder-filter-j-toggle-button');
		this.showResultsButton = document.querySelector('.modelfinder-filter-j-button-results');

		this.getTrackingData = this.getTrackingData.bind(this);

		clickEventService.register(this.getTrackingData);

		ClickEventObjectFilterOpenClose._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			(!eventData_.currentTarget.classList.contains('modelfinder-filter-j-toggle-button') &&
			!eventData_.currentTarget.closest('.modelfinder-filter-j-button-results'))) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: 'navigation',
				eventName: 'model finder - filter ' + this._getToggleState()
			},
			attributes: {
				label: this._getTargetLabel(eventData_.currentTarget)
			}
		};
	}

	/**
	 * Returns the action type that was performed when filter toggle was clicked
	 * @returns {string} toggle state - open | close
	 * @private
	 */
	_getToggleState() {
		let state = '';

		if (this.filter) {
			state = this.filter.dataset.state;
		}

		return (state === 'fold-out') ? 'open' : 'close';
	}

	/**
	 * Returns the label of the clicked item
	 * @param {HTMLElement} target_ - clicked item
	 * @returns {string} item label
	 * @private
	 */
	_getTargetLabel(target_) {
		if (!target_ || !this.showResultsButton || !this.toggleButton) {
			return '';
		}

		if (target_ === this.toggleButton) {
			return (this._getToggleState() === 'open') ? this.toggleButton.dataset.labelOpen : this.toggleButton.dataset.labelClose;
		}
		else {
			let amount = this.showResultsButton.querySelector('.modelfinder-filter-j-button-results-amount').textContent;
			if (amount.length > 0) {
				amount += ' ';
			}

			const text = this.showResultsButton.querySelector('.modelfinder-filter-j-button-results-text').textContent;
			return (amount + text);
		}
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectFilterOpenClose}
 * @public
 * @static
 */
const clickEventObjectFilterOpenClose = new ClickEventObjectFilterOpenClose();
export {clickEventObjectFilterOpenClose, ClickEventObjectFilterOpenClose};
