import {clickEventService} from 'core-tracking-objects';
import {stateRegistry} from 'microkernel';

class ClickEventObjectFilterSelect {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectFilterSelect._instance) {
			return ClickEventObjectFilterSelect._instance;
		}

		this._initialize();

		ClickEventObjectFilterSelect._instance = this;
	}

	/**
	 * intialize module with variables, subscribe to store and register with click event service
	* @returns {void}
	* @private
	*/
	_initialize() {
		this.previouslyActiveFilters = {};
		this.currentlyActiveFilters = {};

		this.modelfinderFilter = document.querySelector('.audi-modelfinder-filter');

		this._handleFilterStoreUpdate = this._handleFilterStoreUpdate.bind(this);
		this._getElementState = this._getElementState.bind(this);
		this._getElementValue = this._getElementValue.bind(this);
		this._getElementLabel = this._getElementLabel.bind(this);
		this.getTrackingData = this.getTrackingData.bind(this);

		stateRegistry.subscribeToStore('dbadModelfinderStore', this._handleFilterStoreUpdate);

		clickEventService.register(this.getTrackingData);
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	* @public
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget ||
			!eventData_.currentTarget.classList ||
			this._getFilterType(eventData_.currentTarget) === 'other') {
			return {};
		}

		const target = eventData_.currentTarget;

		return {
			eventInfo: {
				eventAction: 'filter',
				eventName: 'model finder - ' + this._getElementState(target) + ' filter'
			},
			attributes: {
				elementName: this._getElementName(target),
				label: this._getElementLabel(target),
				value: this._getElementValue(target)
			}
		};
	}

	/**
	* Returns type of clicked filter element
	* @param {HTMLElement} target_ - clicked element
	* @returns {string} filter type
	* @private
	*/
	_getFilterType(target_) {
		if (ClickEventObjectFilterSelect.isElementOfTypeInput(target_)) {
			return 'input';
		}
		else if (ClickEventObjectFilterSelect.isElementOfTypeRange(target_)) {
			return 'range';
		}
		else if (ClickEventObjectFilterSelect.isElementOfTypeReset(target_)) {
			return 'reset';
		}
		else {
			return 'other';
		}
	}

	/**
	* Returns id of clicked filter element
	* @param {HTMLElement} target_ - clicked element
	* @returns {string} filter id
	* @private
	*/
	_getFilterId(target_) {
		if (ClickEventObjectFilterSelect.isElementOfTypeRange(target_)) {
			return (ClickEventObjectFilterSelect.isFeedbackItem(target_)) ? target_.getAttribute('data-id') : target_.name;
		}
		else if (ClickEventObjectFilterSelect.isElementOfTypeInput(target_)) {
			return (ClickEventObjectFilterSelect.isFeedbackItem(target_)) ? target_.getAttribute('data-id') : target_.getAttribute('for');
		}
		else if (ClickEventObjectFilterSelect.isElementOfTypeReset(target_)) {
			return 'reset';
		}

		return '';
	}

	/**
	 * Returns the string for the filter state of the item
	 * @param {HTMLElement} target_ - clicked element
	 * @returns {string} state of clicked filter item remove | add | change
	 * @private
	 */
	_getElementState(target_) {
		// reset button
		if (ClickEventObjectFilterSelect.isElementOfTypeReset(target_)) {
			return 'remove';
		}

		const filterId = this._getFilterId(target_);

		// select / pill for select
		if (ClickEventObjectFilterSelect.isElementOfTypeInput(target_)) {
			if (ClickEventObjectFilterSelect.isFeedbackItem(target_)) {
				return this.modelfinderFilter.querySelector('#' + filterId).checked ? 'add' : 'remove';
			}
			else {
				return this.modelfinderFilter.querySelector('#' + filterId).checked ? 'remove' : 'add';
			}
		}

		// range slider
		const extractedId = ClickEventObjectFilterSelect.extractOptionName(filterId);
		const filterWasSelected = extractedId in this.previouslyActiveFilters;
		const filterIsSelected = extractedId in this.currentlyActiveFilters;

		if (!filterWasSelected && filterIsSelected) {
			return 'add';
		}
		else if (filterWasSelected && !filterIsSelected) {
			return 'remove';
		}

		return 'change';
	}

	/**
	 * Returns the html type of the clicked element
	 * @param {HTMLElement} target_ - clicked element
	 * @returns {string} type of element
	 * @private
	 */
	_getElementName(target_) {
		// reset
		console.log(`getElementName: Element is ${target_.outerHTML}`);
		if (ClickEventObjectFilterSelect.isElementOfTypeRange(target_))	{
			return 'slider';
		}

		// select / reset button
		return 'button';
	}

	/**
	 * Returns the action type that was performed when filter toggle was clicked
	 * @param {HTMLElement} target_ - clicked element
	 * @returns {string} state of clicked filter item remove | add
	 * @private
	 */
	_getElementValue(target_) {
		// reset
		if (ClickEventObjectFilterSelect.isElementOfTypeReset(target_)) {
			return 'all filters removed';
		}

		const filterId = this._getFilterId(target_);

		// input
		if (ClickEventObjectFilterSelect.isElementOfTypeInput(target_)) {
			const filterItem = this.modelfinderFilter.querySelector('label[for="' + filterId + '"]');
			return filterItem.textContent;
		}

		// range slider
		const filterItem = this.modelfinderFilter.querySelector('#' + filterId + '-input');

		return ClickEventObjectFilterSelect.extractOptionName(filterItem.value);
	}

	/**
	 * Returns the label of the clicked filter item
	 * @param {HTMLElement} target_ - clicked element
	 * @returns {string} label of clicked item
	 * @private
	 */
	_getElementLabel(target_) {
		// reset
		if (ClickEventObjectFilterSelect.isElementOfTypeReset(target_)) {
			return this.modelfinderFilter.querySelector('.audi-modelfinder__filter-feedback-list-item-reset').textContent;
		}

		let label = '';
		const filterId = this._getFilterId(target_);

		const type = (ClickEventObjectFilterSelect.isElementOfTypeInput(target_)) ? 'label' : 'output';
		const labelElement = this.modelfinderFilter.querySelector(type + '[for="' + filterId + '"]');

		if (labelElement && labelElement.parentElement && labelElement.parentElement.parentElement) {
			const legend = labelElement.parentElement.parentElement.querySelector('.audi-modelfinder__filter-form-legend');
			label = legend.textContent;
		}

		return label;
	}

	/**
	 * Saves the previously and currently selected filters from the store
	 * @param {Map} state_ - filter
	 * @returns {void}
	 * @private
	 */
	_handleFilterStoreUpdate(state_) {
		this.previouslyActiveFilters = {...this.currentlyActiveFilters};

		if (state_.filter) {
			this.currentlyActiveFilters = {...state_.filter};
		}
	}

	/**
	 * Returns true if element is input or related to input (feedback item) | false
	 * @param {HTMLElement} element_ - element to test for input type
	 * @returns {boolean} - true if element is input | false
	 */
	static isElementOfTypeInput(element_) {
		return (element_.classList.contains('audi-filter-selector__label') || (element_.classList.contains('modelfinder-filter-j-feedback-item') && !element_.querySelector('.modelfinder-filter-j-range-label')));
	}

	/**
	 * Returns true if element is range slider element or related to range slider element (feedback item) | false
	 * @param {HTMLElement} element_ - element to test for range slider type
	 * @returns {boolean} - true if element is range slider | false
	 */
	static isElementOfTypeRange(element_) {
		return (element_.classList.contains('audi-range-slider__input') || (element_.classList.contains('modelfinder-filter-j-feedback-item') && element_.querySelector('.modelfinder-filter-j-range-label')));
	}

	/**
	 * Returns true if element is reset all button | false
	 * @param {HTMLElement} element_ - element to test for reset all type
	 * @returns {boolean} - true if element is reset all button | false
	 */
	static isElementOfTypeReset(element_) {
		return element_.classList.contains('audi-modelfinder__filter-feedback-list-item-reset');
	}

	/**
	 * Returns true if element is feedback item | false
	 * @param {HTMLElement} element_ - element to test for feedback item type
	 * @returns {boolean} - true if element is feedback item | false
	 */
	static isFeedbackItem(element_) {
		return element_.classList.contains('modelfinder-filter-j-feedback-item');
	}

	/**
	 * extract the name of an option
	 * @param {string} rawKey_ - a raw key, with a possible "option" annotation
	 * @returns {string} - a key without a possible "option" annotation
	 */
	static extractOptionName(rawKey_) {
		let key = rawKey_;

		if (key.indexOf('option-') !== -1) {
			key = key.substr(7);
		}

		return key;
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectFilterSelect}
 * @public
 * @static
 */
const clickEventObjectFilterSelect = new ClickEventObjectFilterSelect();
export {clickEventObjectFilterSelect, ClickEventObjectFilterSelect};
